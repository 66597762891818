<template>
  <b-card>
    <b-row>
      <b-col cols="6">
        <b-form-group>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-success"
            to="/campaigns/generator"
          >
            Create Campaign <feather-icon icon="FileTextIcon" size="12" />
          </b-button>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0 d-flex align-items-center"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(image)="data">
            <b-avatar :src="path + data.value" />
          </template>
          <template #cell(created_at)="data">
            {{ setAsLocalDate(data.value) }}
          </template>

          <template #cell(domains)="data">
            <span v-if="data.value.length != 0">
              <!-- {{ data.value[0].domain_name }} -->
              <b-badge
                v-for="vvalue in data.value"
                :key="vvalue.id"
                variant="light-secondary"
                class="mr-1"
              >
                {{ vvalue.domain_name }}
              </b-badge>
              <br />
            </span>
            <b-badge v-if="data.value.length == 0" variant="light-warning">
              none
            </b-badge>
          </template>

          <template #cell(status)="data">
            <b-badge
              v-if="data.item.status == true"
              pill
              variant="success"
              class="badge-glow"
            >
              running
            </b-badge>
            <b-badge
              v-if="data.item.status == false"
              pill
              variant="warning"
              class="badge-glow"
            >
              paused
            </b-badge>
          </template>

          <template #cell(actions)="data">
            <b-button-group>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-info"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover
                title="Edit"
                @click="editCampaign(data.item.id)"
              >
                <feather-icon icon="EditIcon" size="20" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover
                title="Delete"
                @click="confirmDeleteCampaign(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" size="20" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover
                title="Preview"
                style="color: #746af8 !important"
                @click="previewTemplateOfCampaign(data.item.template)"
              >
                <!-- <span class="text-myinfo">texr</span> -->
                <feather-icon icon="EyeIcon" size="20" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-success"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover
                title="Run"
                v-if="data.item.status == false"
                @click="runCampaign(data.item.id)"
              >
                <feather-icon icon="PlayIcon" size="20" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-warning"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover
                title="Pause"
                v-if="data.item.status == true"
                @click="pauseCampaign(data.item.id)"
              >
                <feather-icon icon="PauseIcon" size="20" />
              </b-button>
              <b-button
                style="color: #6d8bb4"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover
                title="Duplicate"
                @click="duplicateCampaign(data.item)"
              >
                <feather-icon icon="CopyIcon" size="20" />
              </b-button>
              <b-button    
              v-if="data.item.campaign_types && data.item.campaign_types.name === 'store'"
              style="color:blue"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat-secondary"
              class="btn-icon rounded-circle"
              v-b-tooltip.hover
              title="chart"
              @click="showChart(data.item.id, data.item.addons && data.item.addons.length > 0 ? data.item.addons[0].product_id : null)"
            >
              <feather-icon icon="BarChartIcon" size="20" />
            </b-button>
         

            </b-button-group>
          </template>
        </b-table>
      </b-col>

      <b-modal
        id="modal-danger"
        ok-variant="danger"
        ok-title="Confirm"
        modal-class="modal-danger"
        centered
        title="Deleting Campaign!"
        @ok="deleteCampaign"
      >
        <b-card-text>
          Are you sure you want to delete this campaign?
        </b-card-text>
      </b-modal>
      <b-col cols="6">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-25"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BTable,
  BAvatar,
  BCardText,
  BImg,
  BBadge,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BButtonGroup,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import store from "@/store";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";

export default {
  components: {
    BTable,
    BAvatar,
    VBTooltip,
    BCard,
    BImg,
    BButtonGroup,
    BBadge,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      path: this.$ImagesURL,
      _id: null,
      perPage: 10,
      pageOptions: [10, 25, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      __order_status: { status: "Pending", variant: "primary" },
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "status",
          label: "Status",
        },
        {
          key: "title",
          label: "name",
        },
        {
          key: "domains",
          label: "Related To",
        },
        {
          key: "views",
          label: "views",
        },
        {
          key: "clicks",
          label: "Clicks",
        },
        {
          key: "created_at",
          label: "Creation Date",
        },
        "Actions",
      ],
      items: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.showAllCampaigns();
  },
  methods: {
    setAsLocalDate(a) {
      let date = new Date(a);
      return date.toLocaleDateString();
    },
    async duplicateCampaign(data) {
      let values = [];
      data.domains.forEach((element) => {
        values.push(element.id);
      });
      const pattern = "Copy - ";
      let patternRes = data.title.search(pattern);
      if (patternRes < 0) {
        data.title = "Copy - " + data.title;
      }
      let allCampaignColumns = {
        title: data.title,
        did: values,
        button_id: data.addons[0].id,
        campaign_type: data.campaign_types.name,
      };
      if (data.campaign_types.name == "app") {
        allCampaignColumns.title_beside_icon =
          data.app_detail.title_beside_icon;
        allCampaignColumns.header_image = data.app_detail.header_image;
        allCampaignColumns.icon_image = data.app_detail.icon_image;
        allCampaignColumns.short_description =
          data.app_detail.short_description;
        allCampaignColumns.template = data.template;
      } else if (data.campaign_types.name == "store") {
        allCampaignColumns.template = data.template;
      }
      await axios
        .post("/store-campaign", allCampaignColumns)
        .then(({ data }) => {
          if (data.status == "success") {
            this.showToast("success", "SaveIcon", "Done", data.message);
            this.showAllCampaigns();
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          }
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Something went wrong!"
          );
        });
    },
    previewTemplateOfCampaign(templateContent) {
      localStorage.setItem("templateContent", JSON.stringify(templateContent));
      let routeData = router.resolve({
        name: "editor-preview-content",
        // query: { data: templateContent },
      });
      window.open(routeData.href, "_blank");
    },
    confirmDeleteCampaign(id) {
      this._id = id;
      this.$root.$emit("bv::show::modal", "modal-danger");
    },
    runCampaign(id) {
      axios
        .post("/run-campaign", {
          id: id,
        })
        .then(({ data }) => {
          if (data.status == "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          } else if (data.status == "success") {
            this.showToast("success", "TrashIcon", "Done", data.message);
            this.showAllCampaigns();
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          } else if (data.status == "warning") {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              data.message
            );
          }
        })
        .catch(console.error);
    },
    pauseCampaign(id) {
      axios
        .post("/pause-campaign", {
          id: id,
        })
        .then(({ data }) => {
          if (data.status == "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          } else if (data.status == "success") {
            this.showToast("success", "TrashIcon", "Done", data.message);
            this.showAllCampaigns();
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          } else if (data.status == "warning") {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              data.message
            );
          }
        })
        .catch(console.error);
    },
    deleteCampaign() {
      axios
        .post("/delete-campaign", {
          id: this._id,
        })
        .then(({ data }) => {
          if (data.status == "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          } else if (data.status == "success") {
            this.showToast("success", "TrashIcon", "Done", data.message);
            this.showAllCampaigns();
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          } else if (data.status == "warning") {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              data.message
            );
          }
        })
        .catch(console.error);
    },
    editCampaign: (id) => {
      store
        .dispatch("appsManager/setCampaignId", id)
        .then((response) => {
          router.push({ name: "campaigns-edit" });
        })
        .catch(console.error);
    },
    showChart: (id, productId) => {
    store .dispatch("appsManager/setCampaignId", { id, productId }) 
    .then(() => {
      router.push({ 
        name: "chartCompain", 
        params: { id, productId }  
      });
    })
    .catch(console.error);
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    async showAllCampaigns() {
      this.$store
        .dispatch("appsManager/getAllCampaigns")
        .then(({ data }) => {
          this.items = data.campaigns;
          this.totalRows = this.items.length;

          // this.items.forEach((campaign) => {
          //   if (campaign.addons && campaign.addons.length > 0) {
          //     campaign.addons.forEach((addon) => {
          //       console.log("Product ID:", addon.product_id);
          //     });
          //   }
          // });

        })
        .catch(console.error);
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
